<template>
    <client-page>

        <sub-visual :tit="product.category" bg="/images/sub/visual/sv--product.jpg"></sub-visual>

        <section class="section--sm section--first">
            <v-container>
                <button @click="$router.go(-1)" class="text-btn d-flex align-center"><i class="icon icon-left-arrow mr-8"></i>Back to product series</button>
                <div class="mt-12 mt-lg-20">
                    <v-row>
                        <v-col cols="12" md="6" class="mb-20 mb-md-0">
                            <div class="prd-slide-wrap rounded">
                                <div class="prd-slide mb-12 mb-lg-20">
                                    <div class="swiper-container">
                                        <div class="swiper-wrapper">
                                            <div class="swiper-slide" v-for="file,idx in product.files" :key="idx">
                                                <div class="thumb white">
                                                    <v-img :src="file.url" class="thumb__inner border rounded"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="prd-thumb-slide px-50 px-lg-76">
                                <v-btn icon tile outlined color="grey-e" class="prd-slide-btn prd-prev"><i class="icon icon-left-arrow2"></i></v-btn>
                                <v-btn icon tile outlined color="grey-e" class="prd-slide-btn prd-next"><i class="icon icon-right-arrow2"></i></v-btn>
                                <div class="swiper-container">
                                    <div class="swiper-wrapper">
                                        <div class="swiper-slide rounded rounded--sm prd-thumb" v-for="file,idx in product.files" :key="idx">
                                            <div class="thumb white" style="cursor: pointer">
                                                <v-img :src="file.url" class="thumb__inner"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12" md="6">
                            <div class="w-100 pl-lg-38">
                                <h4 class="tit--sm font-weight-bold line-height-1 mb-4 mb-lg-10">
                                    {{product.subject}}
                                </h4>
                                <p class="page-text line-height-15 mb-12 mb-lg-20">
                                    {{product.summary}}
                                </p>
                                <ul class="prd__des page-text--sm line-height-15 color-grey-7">
                                    <li>{{product.description[0]}}</li>
                                    <li>{{product.description[1]}}</li>
                                    <li>{{product.description[2]}}</li>
                                    <li>{{product.description[3]}}</li>
                                    <li>{{product.description[4]}}</li>
                                </ul>
                                <v-divider class="border-color-dark my-20 my-lg-40" />
                                <div class="d-flex align-center justify-space-between mb-12 mb-lg-20">
                                    <h5 class="tit-txt font-weight-bold letter-spacing-6 line-height-14">
                                        SIZE & <br>
                                        SPECIFICATIONS
                                    </h5>
                                    <div class="d-flex">
                                        <v-btn icon small outlined color="grey-7" class="sizes-prev mr-4 mr-lg-10"><i class="icon icon-left-arrow-grey icon-left-arrow-grey--sm"></i></v-btn>
                                        <v-btn icon small outlined color="grey-7" class="sizes-next"><i class="icon icon-right-arrow-grey icon-left-arrow-grey--sm"></i></v-btn>
                                    </div>
                                </div>
                                <div class="rounded pa-20 px-lg-16 py-lg-20 grey-f5 mb-14 mb-lg-26">
                                    <div class="sizes-slide">
                                        <div class="swiper-container">
                                            <div class="swiper-wrapper">
                                                <div class="swiper-slide" v-for="el, idx in product.size" :key="idx">
                                                    <div class="size" @click="showDialog(el)" style="cursor: pointer">
                                                        <p class="font-size-12 font-size-lg-14">
                                                            {{el.name}}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex justify-end">
                                    <btn-primary to="/inquiry" tit="INQUIRY"></btn-primary>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </v-container>
        </section>

        <section class="section--sm section--last">
            <v-container>
                <h5 class="tit-txt font-weight-bold letter-spacing-6 line-height-1">
                    DETAIL VIEW
                </h5>
                <v-divider class="border-color-secondary my-20 my-lg-40"></v-divider>
                <div v-html="product.content"></div> 
                <!-- <div class="mt-50 mt-lg-80 d-flex justify-end">
                    <v-btn outlined @click="$router.go(-1)" color="grey-7">LIST</v-btn>
                </div> -->
            </v-container>
        </section>

        <v-dialog max-width="793px" v-model="size" persistent v-if="show==true">
            <v-icon @click="show=false">mdi-close</v-icon>

            <div class="pa-20 pa-md-60">
                <p class="page-text--lg font-weight-bold">{{size.name}}</p>     
                <v-divider class="border-color-secondary my-12 my-lg-20" />
                <p class="page-text line-height-15" style="white-space: pre-line;">
                    {{size.content}}
                </p> 
            </div>           
        </v-dialog>

        <v-dialog max-width="793px" v-model="img" persistent v-if="showImg==true">
            <v-icon @click="showImg=false">mdi-close</v-icon>
            <v-img :src="img" @click="showImg=false"/>
        </v-dialog>

        <!-- <v-card-title>{{product.category}}</v-card-title>
        <v-container>
            <div v-for="file,idx in product.files" :key="idx">
                <v-img :src="file.url" width="300px"/>
            </div>
            {{product.subject}}
            {{product.summary}}
            <div>{{product.description[0]}}</div>
            <div>{{product.description[1]}}</div>
            <div>{{product.description[2]}}</div>
            <div>{{product.description[3]}}</div>
            <div>{{product.description[4]}}</div>
            <v-divider />
            size
            <v-card>
                <div v-for="el, idx in product.size" :key="idx" @click="showDialog(el)">
                    {{el.name}}
                </div>
            </v-card>
        </v-container>

        <div v-html="product.content">
        </div>

        <v-dialog v-model="size" persistent v-if="show==true">
            <v-icon @click="show=false">mdi-close</v-icon>
            {{size.name}}
            <v-divider />
            {{size.content}}
        </v-dialog>  -->

    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/en/templates/ClientPage.vue";
import SubVisual from "@/components/client/en/sub/sub-visual.vue";
import CardPrimary from "@/components/dumb/card-primary.vue";
import BtnPrimary from "@/components/dumb/btn-primary.vue";

import api from "@/api";

export default {
    components: {
        ClientPage,
        SubVisual,
        CardPrimary,
        BtnPrimary,
    },
    data() {
        return {
            product: null,
            show: false,
            showImg: false,
            size: null,
            image_selected: null
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            this.code = this.$route.query.product;
            let { board } = await api.v1.boards.get({ _id: this.$route.params.product });
            this.product = board;

            this.$nextTick(() => {

                setTimeout(function(){

                    this.PrdouctThumb = new Swiper('.prd-thumb-slide .swiper-container', {
                        spaceBetween : 2,
                        slidesPerView: 3,
                        autoplay: {
                            delay: 5000,
                            disableOnInteraction: false,
                        },
                        speed: 500,
                        loop: false,
                        touchRatio: 0,
                        freeMode: true,
                        watchSlidesVisibility: true,
                        watchSlidesProgress: true,
                        breakpoints: {
                            768: {
                                spaceBetween : 2,
                                slidesPerView: 4,
                            },
                            1024: {
                                spaceBetween : 10,
                                slidesPerView: 5,
                            },
                        },
                    });

                    this.Prdouct = new Swiper('.prd-slide .swiper-container', {
                        effect : 'fade',
                        spaceBetween : 0,
                        slidesPerView: 1,
                        autoplay: {
                            delay: 5000,
                            disableOnInteraction: false,
                        },
                        speed: 500,
                        loop: false,
                        thumbs: {
                            swiper: PrdouctThumb,
                        },
                        navigation: {
                            prevEl: '.prd-prev',
                            nextEl: '.prd-next'
                        },
                    });

                }, 10);

                this.Sizes = new Swiper('.sizes-slide .swiper-container', {
                    spaceBetween : 0,
                    slidesPerView: 2,
                    grid: {
                        rows: 2,
                    },
                    // autoplay: {
                    //     delay: 3000,
                    //     disableOnInteraction: false,
                    // },
                    speed: 500,
                    loop: false,
                    navigation: {
                        prevEl: '.sizes-prev',
                        nextEl: '.sizes-next'
                    },
                });
            });
            
        },
        showDialog(el) {
            this.show = true;
            this.size = el;
        },
        showImgDialog(el) {
            this.showImg = true;
            this.img = el;
        },
    },
};
</script>

<style scoped>
.prd-slide-wrap{
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 100%;
}
.prd-slide{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: url(/images/thumb--list.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.prd-slide .swiper-slide{
    cursor: pointer;
}
.prd__des li:not(:last-child){
    margin-bottom: 2px;
}

.sizes-slide{
    height: 64px;
}
.sizes-slide .swiper-container {
    height: 100%;
    overflow: hidden;
}
.sizes-slide .swiper-slide {
    height: 50% !important;
    padding: 2px;
}
.sizes-slide .size{
    height: 100%;
    width: 100%;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #fff;
}

.prd-thumb-slide{
    position: relative;
}
.prd-thumb-slide .swiper-container{
    overflow: hidden;
}
.prd-slide-btn{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.prd-prev{
    left: 0;
}
.prd-next{
    right: 0;
}

.prd-thumb{
    border: 1px solid var(--border-color-dark);
}

.swiper-slide-thumb-active.prd-thumb{
    border: 1px solid var(--v-primary-base);
}

@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .prd__des li:not(:last-child){
        margin-bottom: 4px;
    }
    .sizes-slide{
        height: 84px;
    }
    .sizes-slide .swiper-slide {
        padding: 5px;
    }
}
@media (min-width:1200px){
}

</style>
